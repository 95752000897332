<script setup>
import {getSectorFileName} from '~/globals/globals.js'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {useLocalePath} from 'vue-i18n-routing'

const {locale} = useI18n()
const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

// get route parameters
const route = useRoute()

onMounted(() => {
  const parallaxContainer = document.querySelector(
    '.animate-parallax-container'
  )
  const parallax1Item = document.querySelector('.animate-parallax-1')
  const parallax2Item = document.querySelector('.animate-parallax-2')
  const parallax3Item = document.querySelector('.animate-parallax-3')
  const parallax4Item = document.querySelector('.animate-parallax-4')

  const tlParallax = gsap.timeline()

  if (parallax1Item) {
    tlParallax.from(parallax1Item, {
      yPercent: -20,
      ease: 'Power2.easeOut',
      scrollTrigger: {
        start: 'top 80%',
        trigger: parallaxContainer,
        scrub: 1,
      },
    })
  }

  if (parallax2Item) {
    tlParallax.from(parallax2Item, {
      yPercent: -25,
      ease: 'Power2.easeOut',
      scrollTrigger: {
        trigger: parallaxContainer,
        scrub: 1,
      },
    })
  }

  if (parallax3Item) {
    tlParallax.from(parallax3Item, {
      yPercent: 30,
      ease: 'Power2.easeOut',
      scrollTrigger: {
        trigger: parallaxContainer,
        scrub: 1,
      },
    })
  }

  if (parallax4Item) {
    tlParallax.from(parallax4Item, {
      yPercent: 45,
      ease: 'Power2.easeOut',
      scrollTrigger: {
        trigger: parallaxContainer,
        scrub: 1,
      },
    })
  }
})

// patternname generation
const patternName = ref('')
if (route.params && route.params.sectorSlug) {
  patternName.value = getSectorFileName(route.params.sectorSlug, locale.value)
}
</script>

<template>
  <transition>
    <div class="animate-parallax-container">
      <!-- layout 1 -->
      <section
        v-if="props.content.layout === 1"
        class="introduction"
        :class="'layout--' + props.content.layout">
        <div class="intro-top">
          <div class="intro-top-left">
            <SharedBasicImage
              v-if="props.content.image1 && props.content.image1.assets"
              :assets="props.content.image1.assets"
              :alternative="props.content.image1.alternative"
              class="introduction-image-1 image--16-9 animate-out-left" />
          </div>
          <div class="intro-top-right">
            <SharedBasicImage
              v-if="props.content.image2 && props.content.image2.assets"
              :assets="props.content.image2.assets"
              :alternative="props.content.image2.alternative"
              class="introduction-image-2 image--16-9 animate-out-left" />
            <SharedPattern
              class="introduction-pattern animate-out-left"
              :sector="patternName"
              type="intro"
              v-if="patternName" />
          </div>
        </div>
        <div class="intro-bottom">
          <div class="intro-bottom-left">
            <h2
              class="intro-title animate-in-bottom animate-out-left"
              v-if="props.content.title">
              {{ props.content.title }}
            </h2>
            <div
              v-if="props.content.text"
              class="intro-text animate-in-bottom animate-out-right">
              <p>{{ props.content.text }}</p>
            </div>
            <div
              v-if="props.content.cta"
              class="intro-cta animate-in-bottom animate-out-left">
              <SharedBasicCta
                :label="props.content.cta.label"
                :url="props.content.cta.url"
                color="dark" />
            </div>
          </div>
          <div class="intro-bottom-right">
            <SharedBasicImage
              v-if="props.content.image3 && props.content.image3.assets"
              :assets="props.content.image3.assets"
              :alternative="props.content.image3.alternative"
              class="introduction-image-3 image--16-9 animate-out-left" />
          </div>
        </div>
      </section>

      <!-- layout 2 -->
      <section
        v-if="props.content.layout === 2"
        class="introduction"
        :class="'layout--' + props.content.layout">
        <div class="intro-top">
          <div class="intro-top-left">
            <SharedBasicImage
              v-if="props.content.image1 && props.content.image1.assets"
              :assets="props.content.image1.assets"
              :alternative="props.content.image1.alternative"
              class="introduction-image-1 image--16-9 animate-out-left" />
          </div>
          <div class="intro-top-right">
            <h2
              class="intro-title animate-in-bottom animate-out-left"
              v-if="props.content.title">
              {{ props.content.title }}
            </h2>
            <div
              v-if="props.content.text"
              class="intro-text animate-in-bottom animate-out-right">
              <p>{{ props.content.text }}</p>
            </div>
            <div
              v-if="props.content.cta"
              class="intro-cta animate-in-bottom animate-out-left">
              <SharedBasicCta
                :label="props.content.cta.label"
                :url="props.content.cta.url"
                color="dark" />
            </div>
          </div>
        </div>
        <div class="intro-bottom">
          <div class="intro-bottom-left">
            <SharedBasicImage
              v-if="props.content.image2 && props.content.image2.assets"
              :assets="props.content.image2.assets"
              :alternative="props.content.image2.alternative"
              class="introduction-image-2 image--2-3 animate-out-left" />
          </div>
          <div class="intro-bottom-right">
            <SharedBasicImage
              v-if="props.content.image3 && props.content.image3.assets"
              :assets="props.content.image3.assets"
              :alternative="props.content.image3.alternative"
              class="introduction-image-3 image--16-9 animate-out-left" />
            <SharedPattern
              class="introduction-pattern animate-out-left"
              :sector="patternName"
              type="intro"
              v-if="patternName" />
          </div>
        </div>
      </section>

      <!-- layout 3 -->
      <section
        v-if="props.content.layout === 3"
        class="introduction"
        :class="'layout--' + props.content.layout">
        <div class="intro-top">
          <div class="intro-top-left">
            <SharedBasicImage
              v-if="props.content.image1 && props.content.image1.assets"
              :assets="props.content.image1.assets"
              :alternative="props.content.image1.alternative"
              class="introduction-image-1 image--16-9 animate-out-left" />
          </div>
          <div class="intro-top-right">
            <SharedBasicImage
              v-if="props.content.image2 && props.content.image2.assets"
              :assets="props.content.image2.assets"
              :alternative="props.content.image2.alternative"
              class="introduction-image-2 image--16-9 animate-out-left" />
            <SharedPattern
              class="introduction-pattern animate-out-left"
              :sector="patternName"
              type="intro"
              v-if="patternName" />
          </div>
        </div>
        <div class="intro-bottom">
          <div class="intro-bottom-left">
            <SharedBasicImage
              v-if="props.content.image3 && props.content.image3.assets"
              :assets="props.content.image3.assets"
              :alternative="props.content.image3.alternative"
              class="introduction-image-3 image--16-9 animate-out-left" />
            <SharedPattern
              class="introduction-pattern animate-out-left"
              :sector="patternName"
              type="intro"
              v-if="patternName" />
          </div>
          <div class="intro-bottom-right">
            <h2
              class="intro-title animate-in-bottom animate-out-left"
              v-if="props.content.title">
              {{ props.content.title }}
            </h2>
            <div
              v-if="props.content.text"
              class="intro-text animate-in-bottom animate-out-right">
              <p>{{ props.content.text }}</p>
            </div>
            <div
              v-if="props.content.cta"
              class="intro-cta animate-in-bottom animate-out-left">
              <SharedBasicCta
                :label="props.content.cta.label"
                :url="props.content.cta.url"
                color="dark" />
            </div>
          </div>
        </div>
      </section>

      <!-- layout 4 -->
      <section
        v-if="props.content.layout === 4"
        class="introduction"
        :class="'layout--' + props.content.layout">
        <div class="intro-top">
          <div class="intro-top-left">
            <h2
              class="intro-title animate-in-bottom animate-out-left"
              v-if="props.content.title">
              {{ props.content.title }}
            </h2>
            <div
              v-if="props.content.text"
              class="intro-text animate-in-bottom animate-out-right">
              <p>{{ props.content.text }}</p>
            </div>
            <div
              v-if="props.content.cta"
              class="intro-cta animate-in-bottom animate-out-left">
              <SharedBasicCta
                :label="props.content.cta.label"
                :url="props.content.cta.url"
                color="dark" />
            </div>
          </div>
          <div class="intro-top-right">
            <SharedBasicImage
              v-if="props.content.image1 && props.content.image1.assets"
              :assets="props.content.image1.assets"
              :alternative="props.content.image1.alternative"
              class="introduction-image-1 image--2-3 animate-out-left" />
            <SharedPattern
              class="introduction-pattern animate-out-left"
              :sector="patternName"
              type="intro"
              v-if="patternName" />
          </div>
        </div>
        <div class="intro-bottom">
          <div class="intro-bottom-left">
            <SharedBasicImage
              v-if="props.content.image2 && props.content.image2.assets"
              :assets="props.content.image2.assets"
              :alternative="props.content.image2.alternative"
              class="introduction-image-2 image--2-3 animate-out-left" />
          </div>
          <div class="intro-bottom-right">
            <SharedBasicImage
              v-if="props.content.image3 && props.content.image3.assets"
              :assets="props.content.image3.assets"
              :alternative="props.content.image3.alternative"
              class="introduction-image-3 image--16-9 animate-out-left" />
          </div>
        </div>
      </section>
    </div>
  </transition>
</template>

<style lang="scss">
.introduction {
  padding-bottom: pxrem(90);
  padding-top: pxrem(90);

  @include mq(lg) {
    padding-bottom: pxrem(120);
    padding-top: pxrem(120);
  }

  .image--16-9 img {
    aspect-ratio: 1.77;
    width: 100%;
  }

  .image--2-3 img {
    aspect-ratio: 0.66;
    width: 100%;
  }

  .intro-title {
    @include display-2;
    font-weight: 400;
    margin-bottom: pxrem(20);
  }

  .intro-text {
    margin-bottom: pxrem(20);

    p {
      @include display-3;
      margin-top: 0;
    }
  }

  &.layout--1 {
    .intro-top {
      @include mq(md) {
        display: flex;
        margin-bottom: 20vh;
      }
    }

    .intro-top-left {
      margin-bottom: pxrem(20);
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(1);
        margin-bottom: 0;
      }
    }

    .intro-top-right {
      margin-bottom: pxrem(40);
      margin-left: 25%;
      position: relative;
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(5);
        margin-top: 20vh;
      }
    }

    .intro-bottom {
      @include mq(md) {
        display: flex;
      }
    }

    .intro-bottom-left {
      @include make-col-ready;
      margin-bottom: pxrem(40);

      @include mq(md) {
        @include make-col(8);
        @include make-col-offset(2);
        margin-top: -20vh;
      }
    }

    .intro-bottom-right {
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(1);
      }
    }

    .introduction-pattern {
      left: -10vw;
      position: absolute;
      top: 0;
    }
  }

  &.layout--2 {
    .intro-top {
      @include mq(md) {
        display: flex;
        margin-bottom: 20vh;
      }
    }

    .intro-top-left {
      margin-bottom: pxrem(40);
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(1);
        margin-bottom: 0;
      }
    }

    .intro-top-right {
      @include make-col-ready;
      margin-bottom: pxrem(40);

      @include mq(md) {
        @include make-col(8);
        @include make-col-offset(4);
        margin-bottom: 0;
        margin-top: 20vh;
      }
    }

    .intro-bottom {
      @include mq(md) {
        display: flex;
      }
    }

    .intro-bottom-left {
      margin-bottom: pxrem(20);
      margin-left: 40%;
      width: 50%;

      @include mq(md) {
        @include make-col(6);
        @include make-col-offset(3);
      }
    }

    .intro-bottom-right {
      position: relative;
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(4);
        margin-top: 8vh;
      }
    }

    .introduction-pattern {
      left: -10vw;
      position: absolute;
      top: 0;
    }
  }

  &.layout--3 {
    .intro-top {
      @include mq(md) {
        display: flex;
        margin-bottom: 20vh;
      }
    }

    .intro-top-left {
      margin-bottom: pxrem(40);
      margin-left: 25%;
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(1);

        margin-top: 20vh;
      }
    }

    .intro-top-right {
      margin-bottom: pxrem(20);
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(5);
        margin-bottom: 0;
      }
    }

    .intro-bottom {
      @include mq(md) {
        display: flex;
      }
    }

    .intro-bottom-left {
      margin-bottom: pxrem(40);
      margin-left: 25%;
      position: relative;
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(2);
        margin-bottom: 0;
      }
    }

    .intro-bottom-right {
      @include make-col-ready;

      @include mq(md) {
        @include make-col(8);
        @include make-col-offset(2);
        margin-top: -20vh;
      }
    }

    .introduction-pattern {
      right: -10vw;
      position: absolute;
      top: 0;
    }
  }

  &.layout--4 {
    .intro-top {
      @include mq(md) {
        align-items: center;
        display: flex;
        margin-bottom: 10vh;
      }
    }

    .intro-top-left {
      @include make-col-ready;
      margin-bottom: pxrem(40);

      @include mq(md) {
        @include make-col(8);
        @include make-col-offset(2);
        margin-bottom: 0;
      }
    }

    .intro-top-right {
      margin-bottom: pxrem(20);
      margin-left: 50%;
      position: relative;
      width: 50%;

      @include mq(md) {
        @include make-col(6);
        @include make-col-offset(4);
      }
    }

    .intro-bottom {
      @include mq(md) {
        align-items: center;
        display: flex;
      }
    }

    .intro-bottom-left {
      margin-bottom: pxrem(20);
      width: 50%;

      @include mq(md) {
        @include make-col(6);
        @include make-col-offset(2);
      }
    }

    .intro-bottom-right {
      margin-left: 25%;
      width: 75%;

      @include mq(md) {
        @include make-col(9);
        @include make-col-offset(4);
      }
    }

    .introduction-pattern {
      left: -10vw;
      position: absolute;
      top: 0;
    }
  }
}
</style>
