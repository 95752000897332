<script setup>
import useDebounce from '~/composables/DebounceUtils'
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const maxTabHeight = ref(0)
const tabsNavLabel = ref()
const tabsList = ref()
const tabsListElems = ref([])
const tabsMain = ref()
const tabsContent = ref([])

onMounted(() => {
  // init selected tab for mobile nav
  selectedTab.value = tabsListElems.value[0].querySelector('span').textContent

  // set height for the tabs
  getMaxTabHeight()

  // Add event listener for window resize
  window.addEventListener('resize', debouncedResizeHandler)
})

function getMaxTabHeight() {
  setTimeout(function () {
    if (window.innerWidth >= 768) {
      tabsContent.value.forEach((content) => {
        const height = content.clientHeight
        if (height > maxTabHeight.value) {
          maxTabHeight.value = height
        }
      })
    } else {
      tabsContent.value.forEach((content) => {
        if (content.classList.contains('show')) {
          maxTabHeight.value = content.clientHeight
        }
      })
    }
  }, 750)
}

function getMaxTabHeightNoDelay() {
  if (window.innerWidth >= 768) {
    tabsContent.value.forEach((content) => {
      const height = content.clientHeight
      if (height > maxTabHeight.value) {
        maxTabHeight.value = height
      }
    })
  } else {
    tabsContent.value.forEach((content) => {
      if (content.classList.contains('show')) {
        maxTabHeight.value = content.clientHeight
      }
    })
  }
}

const handleResize = () => {
  // maxTabHeight.value = 0
  getMaxTabHeightNoDelay()
}

const debouncedResizeHandler = useDebounce(handleResize, 300)

const selectedTab = ref()

function showTab(event) {
  const contentElem = document.querySelector('.' + event.target.dataset.target)

  if (contentElem) {
    tabsContent.value.forEach((elem) => {
      elem.classList.remove('show')
    })
    contentElem.classList.add('show')

    tabsListElems.value.forEach((item) => {
      item.classList.remove('active')
    })
    event.target.classList.add('active')

    selectedTab.value = event.target.querySelector('span').textContent

    if (window.innerWidth < 768) {
      maxTabHeight.value = contentElem.clientHeight
      tabsNavLabel.value.classList.remove('open')
      tabsList.value.classList.remove('open')
    }
  }
}

function toggleMobileNavigation(event) {
  tabsNavLabel.value.classList.toggle('open')
  tabsList.value.classList.toggle('open')
}
</script>

<template>
  <section class="section section--tabs">
    <div class="tabs-wrapper">
      <div class="tabs-internal-wrapper">
        <div class="tabs-nav">
          <div
            class="tabs-nav-label animate-in-bottom animate-out-left"
            @click="toggleMobileNavigation"
            ref="tabsNavLabel">
            {{ selectedTab }}
            <svg class="icon icon--btn-round-small icon--blue-stroke">
              <use xlink:href="#icon-chevron"></use>
            </svg>
          </div>
          <ul
            class="tabs-list animate-in-stagger-left animate-out-left"
            ref="tabsList">
            <li
              class="tabs-elem sensible animate-stagger-item"
              :class="[index == 0 ? 'active' : '']"
              v-for="(tab, index) in content.tabs"
              :key="index"
              :data-target="'tabs-content--' + index"
              @click="showTab"
              ref="tabsListElems">
              <span>{{ tab.year }}</span>
            </li>
          </ul>
        </div>

        <div
          class="tabs-main animate-in-stagger-bottom animate-out-right"
          :style="{height: maxTabHeight + 'px'}"
          ref="tabsMain">
          <div
            v-for="(tab, index) in content.tabs"
            :key="index"
            class="tabs-content"
            :class="['tabs-content--' + index, index == 0 ? 'show' : '']"
            ref="tabsContent">
            <div
              class="award-element animate-stagger-item"
              v-for="(award, index) in tab.awards" :key="index">
              <template v-if="award.project_url">
                <NuxtLink
                  class="award-link sensible"
                  :to="localePath(award.project_url)">
                  <div class="award-link-main">
                    <div class="award-link-main-left">
                      <div class="award-link-logo">
                        <img
                          v-if="award.logo_assets"
                          :src="award.logo_assets[0].src"
                          alt="" />
                      </div>
                    </div>
                    <div class="award-link-main-right">
                      <div class="award-link-company">{{ award.company }}</div>
                      <div class="award-link-name">{{ award.name }}</div>
                      <div class="award-link-description">
                        {{ award.description }}
                      </div>
                    </div>
                    <div class="award-link-main-cta">
                      <div class="btn btn--round btn--round-dark">
                        <svg class="icon icon--btn-round">
                          <use xlink:href="#icon-arrow"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="award-link-secondary">
                    <div class="award-link-secondary-left">
                      <div class="cta sensible cta--dark">
                        Discover the project
                        <div
                          class="menu-link-btn btn btn--round-small btn--round-dark">
                          <svg class="icon icon--btn-round-small">
                            <use xlink:href="#icon-arrow"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="award-link-secondary-right">
                      <SharedBasicImage v-if="award.hover_assets" :assets="award.hover_assets" />
                    </div>
                  </div>
                </NuxtLink>
              </template>
              <template v-else>
                <div class="award-link">
                  <div class="award-link-main">
                    <div class="award-link-main-left">
                      <div class="award-link-logo">
                        <img
                          v-if="award.logo_assets && award.logo_assets[0]"
                          :src="award.logo_assets[0].src"
                          alt="" />
                      </div>
                    </div>
                    <div class="award-link-main-right">
                      <div class="award-link-company">{{ award.company }}</div>
                      <div class="award-link-name">{{ award.name }}</div>
                      <div class="award-link-description">
                        {{ award.description }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section--tabs {
  margin-bottom: pxrem(100);
  margin-top: pxrem(100);

  .tabs-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }

  .tabs-internal-wrapper {
    @include mq(md) {
      display: flex;
    }
  }

  .tabs-nav {
    @include mq(md) {
      @include make-col(7);
    }

    @include mq(xxl) {
      @include make-col(5);
    }
  }

  .tabs-nav-label {
    @include label;
    align-items: center;
    border-bottom: 1px solid $color-blue;
    display: flex;
    justify-content: space-between;
    padding-bottom: pxrem(18);

    @include mq(md) {
      display: none;
    }

    svg {
      margin-left: pxrem(10);
      transition: transform $transition-default;
    }

    &.open {
      svg {
        transform: rotateX(180deg);
      }
    }
  }

  .tabs-list {
    @include list-reset;
    max-height: 0;
    overflow: hidden;
    transition: max-height $transition-default 120ms;
    will-change: max-height;

    &.open {
      max-height: 1200px;
    }

    @include mq(md) {
      max-height: 100%;
    }
  }

  .tabs-elem {
    @include label;
    border-bottom: 1px solid lighten($color-gray, 8);
    padding-bottom: pxrem(18) !important;
    padding-top: pxrem(18) !important;

    @include mq(md) {
      &:last-of-type {
        border-bottom: none;
      }
    }

    span {
      pointer-events: none;
      transition: opacity $transition-default;

      @include mq(md) {
        opacity: 0.3;
      }
    }

    @include mq(md) {
      &.active,
      &:hover {
        span {
          opacity: 1;
        }
      }
    }

    &.active {
      display: none;

      @include mq(md) {
        display: block;
      }
    }
  }

  .tabs-main {
    @include make-col-ready;
    margin-top: pxrem(30);
    position: relative;

    @include mq(md) {
      @include make-col(12);
      @include make-col-offset(1);
      margin-top: 0;
    }

    @include mq(xxl) {
      @include make-col-offset(3);
    }
  }

  .tabs-content {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $transition-default;

    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }

  .tabs-content-title {
    @include display-3;
    margin-bottom: pxrem(15);
  }

  .tabs-content-subtitle {
    @include display-3;
    margin-bottom: pxrem(35);
  }

  .tabs-content-text {
    @include paragraph;
    margin-bottom: pxrem(35);
  }

  .tabs-content-cta {
    display: block;
    text-align: center;

    @include mq(md) {
      display: inline-block;
    }
  }

  .award-element {
    border-bottom: 1px solid $color-gray-blue;
    padding-bottom: pxrem(20);
    padding-top: pxrem(20);
  }

  .award-link {
    display: block;
    position: relative;
    text-decoration: none;

    &:hover {
      .award-link-secondary {
        opacity: 1;
      }
    }
  }

  .award-link-main {
    align-items: center;
    display: flex;
  }

  .award-link-main-left {
    width: 10%;
  }

  .award-link-main-right {
    flex-grow: 1;
    padding-left: pxrem(30);
    width: 80%;
  }

  .award-link-main-cta {
    flex-shrink: 0;
  }

  .award-link-logo {
  }

  .award-link-company {
    @include display-3;
    font-weight: 600;
  }

  .award-link-name {
    @include display-3;
  }

  .award-link-description {
    @include paragraph;
  }

  .award-link-secondary {
    background-color: $color-white;
    bottom: 0;
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $transition-default;
  }

  .award-link-secondary-left {
    align-items: center;
    display: flex;
    width: 50%;
  }

  .award-link-secondary-right {
    width: 50%;

    .basic-image {
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}
</style>
